body #creatorElement {
    /* SurveyJS Creator V1 */
    --primary-color: #7ff07f;
    --secondary-color: #1ab394;
    --primary-text-color: #4a4a4a;
    --secondary-text-color: #a7a7a7;
    --inverted-text-color: #ffffff;
    --primary-hover-color: #6fe06f;
    --selection-border-color: #1ab394;
    --primary-icon-color: #3d4d5d;
    --primary-bg-color: #f8f8f8;
    --secondary-bg-color: #f4f4f4;
    --primary-border-color: #e7eaec;
    --secondary-border-color: #ddd;
    --error-color: #ed5565;
}

body {
    --primary: var(--stroke-primary);
    --primary-light: rgba(26, 183, 250, 0.1);
    /* --foreground: #ededed;
    --primary-foreground: #ffffff; */
    --secondary: var(--stroke-primary);
    /* --background: #555555;
    --background-dim: #4d4d4d;
    --background-dim-light: #4d4d4d; */
    --sjs-font-family: Roboto, Helvetica, Arial, sans-serif;
    --sjs-font-surveytitle-size: var();
    --sjs-base-unit: 7px;
    --base-unit: 0.5rem;
    // --small-bold-font-size: 0.75rem;
}

.item-input {
    display: none;
}

.item-root {
    margin-bottom: 8px;
}

.item-svg {
    padding: 4px;
    width: 32px;
    height: 32px;
}

.item-image {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item-text {
    height: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    user-select: none;
}

.item-label {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 32px 4px 16px;
    gap: 12px;
    height: 48px;
    width: fit-content;
    background: rgba(255, 152, 20, 0.1);
    border: 1px solid #ff9814;
    border-radius: 100px;
}

.item-label.item-label--selected {
    background: rgba(25, 179, 148, 0.1);
    border: 1px solid #19b394;
    border-radius: 100px;
}

.question-note {
    white-space: initial;
}

.file-sd-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 74px;
    padding-bottom: 32px;
    gap: 18px;
}

.sd-element__title--expandable {
    padding-left: var(--sjs-base-unit);
}

.sd-root-modern.sd-root-modern--mobile .sd-element__title--expandable:before {
    inset-inline-start: calc(-1.25*(var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sd-element__title--expandable {
    &:before {
        inset-inline-start: calc(-1.75 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
    }
}

.sd-element__title-expandable-svg {
    inset-inline-start: calc(-1.75 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

.sd-body.sd-body--static,
.sd-body.sd-body--responsive {
    padding-top: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

.sd-body.sd-body--responsive {
    padding: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

.sd-root-modern--mobile .sd-body.sd-body--responsive,
.sd-root-modern--mobile .sd-body.sd-body--static {
    padding: calc(1.5*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

.sd-body.sd-body--static .sd-body__page {
    padding-left: calc(4*(var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(4*(var(--sjs-base-unit, var(--base-unit, 8px))));

    & .sd-page__title {
        width: 100%;
    }
}

.sd-body.sd-body--responsive .sd-body__page,
.sd-root-modern--mobile .sd-page.sd-body__page {
    padding: 0;
}

.sd-input {
    padding: calc(1.25*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));

    &.sd-input--readonly {
        background-color: var(--surface-neutral-light);
        cursor: not-allowed;
    }
}

.sd-body {
    background-color: #fff;
    width: 100%;
    max-width: 210mm;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 1rem;
}

.sd-root-modern__wrapper.sd-root-modern__wrapper--has-image .sd-body {
    background-color: transparent;
}

.sd-element__content.sd-panel__content>.sd-row>div>.sd-element--nested.sd-element.sd-question.sd-element--complex {
    padding: 1rem;
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    border-radius: 0.5rem;
}

.svc-element__question-type-selector-icon {
    position: relative;

    & .sv-svg-icon {
        position: absolute;
        left: 0;
    }
}

.svc-page__content,
.svc-page__content:focus,
.svc-hovered.svc-page__content {
    background-color: #fff;
}

.svc-element__header--hidden {
    display: none;
}

.svc-question__content {
    box-shadow: 0 0 0 1px var(--stroke-neutral-base);
}

.svc-question__content--processstep {
    padding: 0;
    box-shadow: unset;
    border-bottom: 1px solid var(--stroke-neutral-base);
    padding-bottom: 1.5rem;

    & .svc-question__drag-area,
    & .svc-question__content-actions {
        display: none;
    }
}

.svc-element__add-new-question.svc-btn {
    box-shadow: 0 0 0 1px var(--stroke-neutral-base);
}

.svc-question__content--panel{
    padding:10px !important
}

.surveyFormPanel {
    & .sv-components-column.sv-components-column--expandable {
        padding: 1rem;

        & .sd-body.sd-body--static {
            margin-top: 0;
            border: 1px solid var(--stroke-neutral-base);
            border-radius: 0.5rem;
            background-color: var(--surface-neutral-base);

            & .sd-title.sd-page__title {
                &~.sd-page__row.sd-row--compact {
                    margin-top: 0;
                    padding-top: 1rem;
                }

                &~.sd-page__row.sd-row--compact:first-of-type {
                    margin-top: 0;
                    padding-top: 0;
                }

                & .sv-title-actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    & .sv-action {
                        margin-right: .5rem;
                    }

                    & .sv-title-actions__title {
                        //order: 1;
                        width: 100%;
                    }

                    & .sd-action-bar.sv-action-title-bar {
                        flex: auto;
                        width: auto;
                        justify-content: flex-start;

                        & #complete-step button.sd-action {
                            height: 2rem;
                            min-height: 2rem;
                            padding: 0 1rem;
                            font: var(--body2-medium);
                            //background-color: var(--surface-brand-primary-semi-dark);
                            background-color: #0078d6;
                            color: var(--content-neutral-white);
                            //border: 1px solid var(--stroke-primary);
                            border: 1px solid #0078d6;
                            border-radius: 0.5rem;
                            max-height: 2rem;
                            font-size: .75rem;
                            font-weight: 500;
                        }
                    }
                }
            }

            & .sd-element__title .sd-element__num {
                width: auto;
                margin-inline-start: unset;
            }
        }

        & .sd-action-bar.sd-footer {
            margin-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            padding: 0.5rem 1rem !important;
            // margin-left: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            // margin-right: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            margin-bottom: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
            background-color: var(--surface-neutral-light);
            border-top: 1px solid var(--stroke-neutral-base);
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            display: flex;
            justify-content: flex-end;

            & .sv-action {
                flex-grow: unset;

                & .sd-btn {
                    height: 2rem;
                    min-height: 2rem;
                    padding: 0.25rem 1rem;
                    font-size: var(--body2-medium);
                    min-width: 80px;
                }
            }
        }


    }
}

@media only screen and (max-width: 576px) {

    .sd-root-modern--mobile .sd-body.sd-body--responsive,
    .sd-root-modern--mobile .sd-body.sd-body--static {
        padding-left: calc(1*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
        padding-right: calc(1*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
    }

}


/* Basic editor styles */
.tiptap {
    :first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty;
    }

    h1,
    h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: 'JetBrainsMono', monospace;
        margin: 1.5rem 0;
        padding: 0.75rem 1rem;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
        }
    }

    blockquote {
        border-left: 3px solid var(--gray-3);
        margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid var(--gray-2);
        margin: 2rem 0;
    }
}